import type { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import { cn } from "../lib/utils";
import { buttonVariants } from "./ui/button";

interface Props extends HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
    icon: ReactNode;
  }[];
}

export function Sidebar({ className, items, ...props }: Props) {
  const pathname = window.location.pathname;

  return (
    <nav className={cn("gap-4 sm:gap-2 flex flex-col", className)} {...props}>
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            pathname === item.href
              ? "bg-muted hover:bg-muted"
              : "hover:bg-transparent hover:underline",
            "justify-start",
          )}
        >
          {item.icon}
          <div className={cn("w-3")} />
          {item.title}
        </Link>
      ))}
    </nav>
  );
}

import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, {
  type FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { deleteNews, fetchNews, postNews } from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function NewsNotifierPage() {
  const session = useContext(SessionContext);
  const [newCode, setNewCode] = useState("");
  const [items, setItems] = useState<null | WatchingStock>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null | Stock>(null);

  const doSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    setIsCreating(true);
    await doCreateNews(newCode);
    setNewCode("");
    setIsCreating(false);
  };

  const doDeleteNews = async (code: string) => {
    const response = await deleteNews(code);
    if (response.status < 200 || response.status > 299) {
      return;
    }
    setSelectedItem(null);
    await fetch();
  };

  const doCreateNews = async (code: string) => {
    const response = await postNews(code);
    if (response.status < 200 || response.status > 299) {
      return;
    }
    await fetch();
  };

  const fetch = useCallback(async () => {
    const response = await fetchNews();
    if (response.status < 200 || response.status > 299) {
      return;
    }
    const data = JSON.parse(await response.text()) as WatchingStock;
    setItems(data);
  }, []);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  // Guard no-authenticated
  if (!session?.username) return <></>;

  return (
    <DashboardLayout>
      <div className={cn("flex flex-col w-full gap-8")}>
        <h1 className={cn("text-4xl")}>IR Notifier</h1>

        <div className={cn("bg-border min-w-screen h-[1px] mt-1 mb-2")} />

        <form
          className="flex gap-4 items-center justify-center"
          onSubmit={doSubmit}
        >
          <Input
            type="text"
            placeholder="Code"
            value={newCode}
            required
            onChange={(e) => setNewCode(e.target.value)}
          />
          <Button type="submit" variant="secondary" disabled={isCreating}>
            Add
          </Button>
        </form>

        {selectedItem ? (
          <div className={cn("flex items-center")}>
            <div>
              <span className={cn("text-sm")}>{selectedItem.name}</span>
              <div className={cn("w-4")} />
              <span className={cn("text-xs")}>{selectedItem.code}</span>
            </div>
            <div className={cn("w-12")} />
            <Button
              variant={"destructive"}
              onClick={() => doDeleteNews(selectedItem.code)}
            >
              Delete
            </Button>
            <div className={cn("w-2")} />
            <Button variant={"secondary"} onClick={() => setSelectedItem(null)}>
              Cancel
            </Button>
          </div>
        ) : null}

        {items !== null ? (
          <DataTable
            columns={columns}
            data={items.watching}
            onSelect={setSelectedItem}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </DashboardLayout>
  );
}

export const columns: ColumnDef<Stock>[] = [
  {
    accessorKey: "code",
    header: "Code",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
];

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onSelect: (row: TData) => void;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  onSelect,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onSelect(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

interface Stock {
  code: string;
  name: string;
}

interface WatchingStock {
  watching: Stock[];
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import App from "./App";
import { cn } from "./lib/utils";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <div className={cn("min-h-dvh bg-background font-sans antialiased")}>
      <App />
    </div>
  </React.StrictMode>,
);

import {
  type CSSProperties,
  type FormEventHandler,
  useContext,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { login } from "../data/api";

export default function LoginPage() {
  const noticeStyle: CSSProperties = {
    fontSize: 13,
    background: "#a53131",
    padding: "12px 40px",
    borderRadius: 4,
  };

  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<null | string>(null);

  const doSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    login(username, password)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error(`Status Error (${response.status})`);
        }
        const json = JSON.parse(await response.text());
        session?.setUsername(json.user);
        navigate("/");
      })
      .catch((reason) => setError(reason.toString()));
  };
  return (
    <main className="flex flex-col items-center justify-center min-h-dvh">
      <form
        className="flex flex-col items-center justify-center"
        onSubmit={doSubmit}
      >
        <Input
          type="text"
          placeholder="Username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <div style={{ height: 16 }} />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <div style={{ height: 32 }} />
        <Button type="submit">Login</Button>
      </form>
      {error ? (
        <>
          <div style={{ height: 64 }} />
          <div style={noticeStyle}>{error}</div>
        </>
      ) : null}
      <div style={{ height: 64 }} />
      <Link to="/">Home</Link>
    </main>
  );
}

import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { fetchSession } from "./data/api";
import DividendPage from "./pages/DividendPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NewsNotifierPage from "./pages/NewsNotifierPage";
import PortfolioDetailPage from "./pages/PortfolioDetailPage";
import PortfolioPage from "./pages/PortfolioPage";

export type SessionContextType = {
  username: string | null;
  setUsername: (username: string | null) => void;
};
export const SessionContext = createContext<SessionContextType | null>(null);

function App() {
  const [username, setUsername] = useState<string | null>(null);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/dividend",
      element: <DividendPage />,
    },
    {
      path: "/portfolio",
      element: <PortfolioPage />,
    },
    {
      path: "/portfolio/:id",
      element: <PortfolioDetailPage />,
    },
    {
      path: "/news",
      element: <NewsNotifierPage />,
    },
  ]);

  useEffect(() => {
    (async () => {
      const response = await fetchSession();
      if (response.status < 200 || response.status > 299) {
        return;
      }
      const json = JSON.parse(await response.text());
      setUsername(json.user);
    })();
  }, []);
  return (
    <SessionContext.Provider value={{ username, setUsername }}>
      <RouterProvider router={router} />
    </SessionContext.Provider>
  );
}

export default App;

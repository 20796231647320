import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "../components/ui/drawer";
import { Input } from "../components/ui/input";
import { fetchPortfolio, postPortfolio } from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function PortfolioPage() {
	const session = useContext(SessionContext);
	const [response, setResponse] = useState<PortfolioItems | null>();
	const [createOpen, setCreateOpen] = useState(false);
	const [newStockCode, setNewStockCode] = useState("");
	const [newVolume, setNewVolume] = useState("");
	const [newAveragePrice, setNewAveragePrice] = useState("");

	const fetch = useCallback(async () => {
		const response = await fetchPortfolio();
		if (response.status < 200 || response.status > 299) {
			return;
		}
		const data = JSON.parse(await response.text()) as PortfolioItems;
		setResponse(data);
	}, []);

	const doCreate = async () => {
		const response = await postPortfolio(
			newStockCode,
			newVolume,
			newAveragePrice,
		);
		if (response.status < 200 || response.status > 299) {
			return;
		}
		await fetch();

		setNewStockCode("");
		setNewVolume("");
		setNewAveragePrice("");
		setCreateOpen(false);
	};

	useEffect(() => {
		(async () => await fetch())();
	}, [fetch]);

	// Guard no-authenticated
	if (!session?.username) return <></>;

	const createDrawer = (
		<Drawer open={createOpen} onOpenChange={setCreateOpen}>
			<DrawerTrigger asChild>
				<Button variant="outline">Add</Button>
			</DrawerTrigger>
			<DrawerContent>
				<div className="mx-auto w-full max-w-sm">
					<DrawerHeader>
						<DrawerTitle>Add Stock to My Portfolio</DrawerTitle>
						<DrawerDescription />
					</DrawerHeader>
					<div className="p-4 pb-0">
						<Input
							type="text"
							inputMode="decimal"
							placeholder="Stock Code"
							value={newStockCode}
							onChange={(e) => setNewStockCode(e.target.value)}
							required
						/>
						<div style={{ height: 16 }} />
						<Input
							type="text"
							inputMode="decimal"
							placeholder="Volume"
							value={newVolume}
							onChange={(e) => setNewVolume(e.target.value)}
							required
						/>
						<div style={{ height: 16 }} />
						<Input
							type="text"
							inputMode="decimal"
							placeholder="Ave Unit Price"
							value={newAveragePrice}
							onChange={(e) => setNewAveragePrice(e.target.value)}
							required
						/>
						<div style={{ height: 16 }} />
					</div>
					<DrawerFooter>
						<Button onClick={doCreate}>Submit</Button>
						<DrawerClose asChild>
							<Button variant="outline">Cancel</Button>
						</DrawerClose>
					</DrawerFooter>
				</div>
			</DrawerContent>
		</Drawer>
	);

	return (
		<DashboardLayout>
			<main className={cn("w-full")}>
				<header className={cn("pb-6 flex justify-end w-full")}>
					{createDrawer}
				</header>
				<div className={cn("flex flex-col gap-6")}>
					<div
						className={cn(
							"px-4 py-3 flex flex-col border-2 rounded mb-2 gap-2 bg-secondary",
						)}
					>
						<div className={cn("text font-bold")}>
							¥{response?.summary.total.toLocaleString() ?? "..."}
						</div>
						<div className={cn("text-sm flex gap-2")}>
							<span>
								¥{response?.summary.dividend.toLocaleString() ?? "..."}
							</span>
							<span>
								({((response?.summary.rate ?? 0) * 100).toFixed(2) ?? "..."}%)
							</span>
						</div>
					</div>

					{response?.items.map((item) => {
						const rate =
							(item.stock.dividend_price /
								(item.user_stock.ave_unit_price ?? 0)) *
							100;

						return (
							<Link
								to={`/portfolio/${item.user_stock.id}`}
								key={item.user_stock.id}
								className={cn(
									"px-4 py-3 flex flex-col border-2 rounded mb-2 gap-2",
								)}
							>
								<div className={cn("flex items-center")}>
									<div className={cn("font-bold")}>
										# {item.user_stock.stock_code}
									</div>
									<div className={cn("w-2")} />
									<div className={cn("flex text-xs text-muted-foreground")}>
										{item.stock.name}
									</div>
								</div>
								<div
									className={cn(
										"flex flex-col lg:flex-row lg:justify-start lg:items-center gap-2 lg:gap-4",
									)}
								>
									<div className={cn("flex w-32 text-sm")}>
										<div>{item.user_stock.volume}</div>
										<div className={cn("w-2")} />
										<div>×</div>
										<div className={cn("w-2")} />
										<div>
											¥{item.user_stock.ave_unit_price?.toLocaleString()}
										</div>
									</div>
									<div className={cn("flex w-80 text-sm")}>
										<div>¥{item.stock.dividend_price.toLocaleString()}</div>
										<div className={cn("w-2")} />
										<div>{rate.toFixed(2)}%</div>
										<div className={cn("w-2")} />
										<div>({(rate - item.stock.dividend_rate).toFixed(2)}%)</div>
										<div className={cn("w-6")} />
										<div>{item.stock.dividend_date_1}</div>
										<div className={cn("w-2")} />
										<div>{item.stock.dividend_date_2}</div>
									</div>
									<div className={cn("flex flex-1 text-sm")}>
										<div>{item.user_stock.note_stop_loss ?? "??"}</div>
										<div className={cn("w-2")} />
										<div>...</div>
										<div className={cn("w-2")} />
										<div>{item.user_stock.note_take_profit ?? "??"}</div>
										<div className={cn("w-4")} />
										<div>
											| {item.user_stock.note_account_closing_date ?? "-"}
										</div>
									</div>
									<div className={cn("flex flex-1 justify-end")}>
										<a
											className={cn("bg-secondary py-2 px-4 inline-block")}
											href={`https://www.buffett-code.com/company/${item.user_stock.stock_code}/`}
										>
											BC
										</a>
									</div>
								</div>
							</Link>
						);
					})}
				</div>
				{response?.items.length === 0 ? (
					<tr>
						<td>empty.</td>
					</tr>
				) : null}
			</main>
		</DashboardLayout>
	);
}
